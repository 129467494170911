import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class WebtasarimPage extends React.Component {
  render() {

    const data = this.props.data

        //control
        const pic05 = data.pic05.childImageSharp.fluid
        const pic06 = data.pic06.childImageSharp.fluid
        const pagebgeticaret = data.pagebgeticaret.childImageSharp.fluid


return (
  <Layout pageTitle="E-Ticaret">
    <SEO title="E-Ticaret - Pazar Yeri - Entegrasyon Sistemleri" keywords={[`e-ticaret`, `pos entegre`, `market yeri`]} description="İstanbul Web Tasarım farkı ile işlek bir cadde de bayi açın ! E-Ticaret - pazaryeri yazılımı" />
    <section className="bginpage">
    <Img fluid={pagebgeticaret}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Web Tasarım"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        E-Ticaret
             </h1>
             <p> Dünyada E-Ticaretin Hacmi her geçen gün yükseliyor.Önümüzde ki 10 yıl içerisinde bir çok avm nin unutulacağı gerçeği göz ardı etmeyin! İşlek bir cadde bayi açmak için hazırmısınız ?</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md={{ size: 10, offset: 1 }}  className="text-center pdbot30">
      <h1 className="font-size-normal  section-title">
               <small>E-Ticaret / Xml Entegrasyon / Marketplace (Pazaryeri) Entegrasyonları</small>
               E-Ticaret
                <div className="bar"></div>
           </h1>
           <p>E-ticaret sistemlerimiz web tabanlı olan otomasyon sistemleri ile donatılmış ve ürünlerinizi potansiyel müşterilerinize en iyi şekilde aktarabileceğiniz sistemlerdir. Her e-ticaret yazılımın farklı müşteri kitlesine ulaştığının farkındayız ve bununla beraber farklı ihtiyaçlarının olduğununda bilincindeyiz.İnternetteki vitrinizin hayallerinizdeki gibi olması için sizlere kullanımı kolay son derece gelişmiş ,seo omurgası oturmuş ,esnek e-ticaret sistemleri sunuyoruz.Sizlerin ticaret hayatında başarılarınızın bizlerin onur ve gurur kaynağımız olduğunu yeniden belirterek ;başarılı bir e-ticaret sisteminin internet ortamındaki değişimlere ayak uydurmasını kullanıcı beklentilerine cevap vermesi ve sizleri arama motorlarında üst sıralara çıkartmasından geçtiğinin bilincinde olan İstanbulwebtasarım ekibi sizler için esnek uyumlu ve son derece şık e-ticaret sistemleri hazırlamaktadır.</p>
           </Col>
           </Row>

           <Row>
            <Col md="4">
             <Img fluid={pic05} alt="Mobil Web Tasarım" className="mt-50" />

            </Col>
            <Col md="8">
              <Row>
              <Col md="12" lg="12">
              <div className="single-features">
                <div className="icon">
                <i className="icon-3x fa fa-desktop"></i>
                </div>

                <h3 className="font-size-normal">Profesyonel e-ticaret çözümü</h3>
                <p>Markanız için geliştireceğimiz Dijital Pazarlama stratejilerinde Hedef Kitlenizi ve hedef kitlenizin isteklerini en iyi şekilde belirlerleyerek Alan adı, hosting, e-mail, güvenlik, yedekleme, ödeme altyapıları ve diğer birçok hizmetle beraber sizlere paket satmak yerine çözüm ortağı oluyoruz.</p>
              </div>
              </Col>
              </Row>
              <Row>
              <Col md="12" lg="12">
              <div className="single-features">
                <div className="icon">
                <i className="icon-3x fa fa-desktop"></i>
                </div>
<h3 className="font-size-normal">Kullanımı Kolay ve Entegrasyonlara Açık</h3>
                <p>E-ticaret sistemlerini kullanımı kolay navigasyonlar ile sizlere sunuyoruz.Farklı firmalarla anlaşmalarınız dogrultusunda xml entegrasyon vb süreçleri sizler için işin içinden çıkılmaz bir hal almasının onune ket vuruyoruz.</p>
              </div>
              </Col>
              </Row>
              <Row>
              <Col md="12" lg="12">
              <div className="single-features">
                <div className="icon">
                <i className="icon-3x fa fa-desktop"></i>
                </div>
                <h3 className="font-size-normal">Teknik Destek ve Özel Sertifakalı Eğitimler</h3>
                <p>Güncel pazarlama stratejileri üzerine alanında ihtisas yapmış kişilerce sürekli olarak konferanslarımızda sizlerle birlikte olmaktan gurur duyuyoruz.Ayrıca İstanbul Web Tasarım ticket cevaplama süresi ortalama 30 dk olup en iyi desteği sunar</p>
              </div>
              </Col>
              </Row>
            </Col>
           </Row>


           <Row>
            <Col md="8">
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Arama Motoru Optimizasyonu</h3>
              <p>Müşterilerimizin istek ve hedeflerini iyi anlayıp, onları iş ortağı gören bir anlayış ile web ortamındaki ihtiyaçlarını karşılamak için tüm enerji ve birikimimizi potansiyel müşterilerinize ulaşabilmeniz yönünde kullanıyoruz.e-Ticaret Sistemlerimizin gelişmiş bir seo omurgasına sahiptir.</p>
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Markanıza Özel E-Ticaret Tasarımları</h3>
              <p>İstanbul Web Tasarım e-Ticaret Sistemlerinde Logonuza ve iş yapıtğınız sektöre özel tasarımlar ile sizleri kalıpların dışına çıkartır.Yaptığımız web siteleri tüm mobil cihazlara (iPhone, iPad, Samsung v.b) ve tüm tarayıcılarda (Chrome, İExplorer, Firefox, v.b) kusursuz bir esneklik ile çalışmaktadır.</p>
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">
Sanal Pos Entegresi / Xml Entegrasyon / Kargo</h3>
              <p>İstanbul Web Tasarım e-Ticaret projelerinde kısıtlamalar yalnızca müşterilerine özgüdür.Xml Entegrasyon , Kargo Entegrasyonu , Sanal Pos Entegrasyonları için ekstra ücret talep etmez.</p>
            </div>
            </Col>
            </Row>
            </Col>
            <Col md="4">
             <Img fluid={pic06} alt="Mobil Web Tasarım" className="mt-50" />
            </Col>
           </Row>

           <Row>
            <Col md="6">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Kaçınılmaz Sonuçlar</h3>
              <p>Sizin için oluşturduğumuz çalışma planı sonucunda potansiyel müşterilerinize ulaşabiliyor ve onlara kurumsal kimliğinizi , ürünlerinizi ve hizmetlerinizi en doğru şekilde iletebilirsiniz.</p>
            </div>
            </Col>
            <Col md="6">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Yedekleme ve Güncelleme</h3>
              <p>Sizler için ve sizlerinde yardımıyla sürekli olarak çalışarak oluşturduğumuz web sitelerinizi en güncel halleri ile belirli periyotlarla yedeklerini alıyor ve gerekli güncellemeleri yapıyoruz.</p>
            </div>
            </Col>
           </Row>
           <Row>

           </Row>


    </Container>
    </section>


  </Layout>
)

}
}
export const pageQuery = graphql`
query {
pic05: file(
 relativePath: { regex: "/mobile-view-map1.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pic06: file(
 relativePath: { regex: "/mobile-two.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pagebgeticaret: file(
 relativePath: { regex: "/bg14.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
